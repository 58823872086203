import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import {
    ExpandMoreIcon,
    ArrowDropUpIcon,
    EmailIcon,
    SmsIcon,
    WarningIcon,
    ReportIcon,
} from '../../assets/Icons/Icons.js';

import { useUser } from '../../contexts/UserProvider';
import { useApi } from '../../contexts/ApiProvider';
import { useAlert } from '../../contexts/AlertsProvider';
import AppContainer from '../../components/shared/AppContainer';
import AppHeader from '../../components/shared/AppHeader';
import AppCard from '../../components/shared/AppCard';
import OptionItem from '../../components/settings/OptionItem';
import AppDivider from '../../components/shared/AppDivider';
import AppDrawer from "../../components/shared/AppDrawer";

function SettingsScreen() {
    const { t , i18n} = useTranslation('settings');

    const { user, setUser, isContractor } = useUser();
    const api = useApi();
    const alert = useAlert();
    const notificationsEnabled = user.phone_numbers[0]?.enable_alerts;

    const [isMethodExpanded, setMethodExpanded] = useState(false);
    const [isErrorTypesExpanded, setErrorTypesExpanded] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const languageMapping = {
        0: 'en',
        1: 'fr',
    };

    const languageOptions = [
        { label: t('english'), value: 0 },
        { label: t('french'), value: 1 },
    ];

    const unitOptions = [
        { label: t('metric'), value: true },
        { label: t('imperial'), value: false },
    ];

    const notificationMethods = [
        { label: t('email'), field: 'notify_email', icon: <EmailIcon color="secondary" /> },
        { label: t('sms'), field: 'notify_sms', icon: <SmsIcon color="secondary" /> },
    ];

    const errorTypes = [
        ...(isContractor
            ? [
                {
                    label: t('warnings'),
                    field: 'notify_warning',
                    icon: <WarningIcon color="secondary" />,
                },
            ]
            : []),
        {
            label: t('lockouts'),
            field: 'notify_error',
            icon: <ReportIcon color="secondary" />,
        },
    ];

    const updateUserSettings = async (updates) => {
        const response = await api.put('/me', updates);
        if (response.ok) {
            setUser((prevState) => ({ ...prevState, ...updates }));

            // Check if the language is being updated
            if (updates.hasOwnProperty('language')) {
                const newLanguageCode = languageMapping[updates.language];
                if (newLanguageCode) {
                    i18n.changeLanguage(newLanguageCode);
                }
            }
            alert(t('settingsUpdated'), 'success');
        } else {
            alert(t('settingsUpdateFailed'), 'error');
        }
    };

    return (
        <AppContainer>
            <AppHeader title={t('appHeaderTitle')} backButtonRoute={`/dashboard`} />
            <Stack spacing={2} mt={2}>
                {/* Language Selection */}
                <Stack>
                    <Typography gutterBottom>{t('preferredLanguage')}</Typography>
                    <AppCard type="good">
                        {languageOptions.map((option, index) => (
                            <React.Fragment key={option.value}>
                                {index > 0 && <AppDivider inCard="true" />}
                                <OptionItem
                                    label={option.label}
                                    selected={user.language === option.value}
                                    onClick={() => updateUserSettings({ language: option.value })}
                                />
                            </React.Fragment>
                        ))}
                    </AppCard>
                </Stack>
                {/* Units Selection */}
                <Stack>
                    <Typography gutterBottom>{t('preferredUnits')}</Typography>
                    <AppCard type="good">
                        {unitOptions.map((option, index) => (
                            <React.Fragment key={option.label}>
                                {index > 0 && <AppDivider inCard="true" />}
                                <OptionItem
                                    label={option.label}
                                    selected={user.metric === option.value}
                                    onClick={() => updateUserSettings({ metric: option.value })}
                                />
                            </React.Fragment>
                        ))}
                    </AppCard>
                </Stack>
                {/* Notifications */}
                {notificationsEnabled && (
                    <Stack>
                        <Typography gutterBottom >{t('notificationsSettings')}</Typography>
                        <AppCard type="good">
                            {/* Methods */}
                            <Card variant="outlined" sx={{ borderRadius: '8px' }}>
                                <CardHeader
                                    title={<Typography >{t('methods')}</Typography>}
                                    action={
                                        <ExpandMoreIcon
                                            fontSize="medium"
                                            color="secondary"
                                            style={{ transform: isMethodExpanded ? 'rotate(180deg)' : 'rotate(0deg)', cursor: 'pointer' }}
                                            onClick={() => setMethodExpanded(!isMethodExpanded)}
                                        />
                                    }
                                />
                                <Collapse in={isMethodExpanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        {notificationMethods.map((method, index) => (
                                            <React.Fragment key={method.field}>
                                                {index > 0 && <AppDivider inCard="true" />}
                                                <OptionItem
                                                    label={method.label}
                                                    selected={user[method.field]}
                                                    onClick={() => updateUserSettings({ [method.field]: !user[method.field] })}
                                                    icon={method.icon}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </CardContent>
                                    <Typography textAlign="center" variant="body2" px={1}>
                                        {t('messageDataRates')}
                                    </Typography>
                                </Collapse>
                            </Card>
                            {/* Error Types */}
                            <Card variant="outlined" sx={{ marginTop: '0.3em', borderRadius: '8px' }}>
                                <CardHeader
                                    title={<Typography>{t('notificationTypes')}</Typography>}
                                    action={
                                        <ExpandMoreIcon
                                            fontSize="medium"
                                            color="secondary"
                                            style={{ transform: isErrorTypesExpanded ? 'rotate(180deg)' : 'rotate(0deg)', cursor: 'pointer' }}
                                            onClick={() => setErrorTypesExpanded(!isErrorTypesExpanded)}
                                        />
                                    }
                                />
                                <Collapse in={isErrorTypesExpanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        {errorTypes.map((type, index) => (
                                            <React.Fragment key={type.field}>
                                                {index > 0 && <AppDivider inCard="true" />}
                                                <OptionItem
                                                    label={type.label}
                                                    selected={user[type.field]}
                                                    onClick={() => updateUserSettings({ [type.field]: !user[type.field] })}
                                                    icon={type.icon}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </CardContent>
                                </Collapse>
                            </Card>
                        </AppCard>
                        <Stack alignItems="center" direction="row">
                            <Typography color="secondaryText" variant="body2" ml={1}>
                                {t('learnMore')}
                            </Typography>
                            <IconButton onClick={() => setDrawerOpen(true)} style={{ cursor: 'pointer' }}>
                                <ArrowDropUpIcon color="secondary" />
                            </IconButton>
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <Typography textAlign="center" variant="body2" mt="auto">
                {t('appVersion')}
            </Typography>

            <AppDrawer title={t('notificationTypes')} open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
                <Stack mt={1}>
                    <Typography variant="h6">{t('hardLockouts')}</Typography>
                    <Typography variant="body2" gutterBottom>
                        {t('hardLockoutsDescription')}
                    </Typography>
                    {isContractor && (
                        <>
                            <Typography variant="h6">{t('softLockouts')}</Typography>
                            <Typography variant="body2" gutterBottom>
                                {t('softLockoutsDescription')}
                            </Typography>
                            <Typography variant="h6">{t('warnings')}</Typography>
                            <Typography variant="body2">
                                {t('warningsDescription')}
                            </Typography>
                        </>
                    )}
                </Stack>
            </AppDrawer>

        </AppContainer>
    );
}

export default SettingsScreen;
