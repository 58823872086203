import React, { useState } from 'react';
import { Formik, Form, useFormikContext, useField } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';

import AppContainer from '../shared/AppContainer';
import AppHeader from '../shared/AppHeader';
import AppDialog from '../shared/AppDialog';
import PasswordDialog from './PasswordDialog';
import SuccessAnimation from '../../assets/Lotties/SuccessAnimation';
import FormIcon from '../../assets/Icons/FormIcon';

import { useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/ApiProvider';

//TODO: Use the helper functions from the other file instead of this below.
function formatState(value = '') {
    return value.toUpperCase().slice(0, 2);
}

function formatZipCode(value = '') {
    return value.replace(/[^\d]/g, '').slice(0, 5);
}

function formatPostalCode(value = '') {
    let cleaned = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    if (cleaned.length > 3) {
        cleaned = cleaned.slice(0, 3) + ' ' + cleaned.slice(3);
    }
    return cleaned.slice(0, 7);
}

const StateOrProvinceInput = ({ label, name, ...props }) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e) => {
        let val = e.target.value;
        if (values.country === 'US') {
            val = formatState(val);
        } else if (values.country === 'CA') {
            val = val.toUpperCase().slice(0, 2);
        }
        setFieldValue(name, val);
    };

    return (
        <TextField
            {...field}
            {...props}
            name={name}
            label={label}
            value={field.value || ''}
            onChange={handleChange}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            fullWidth
            variant="standard"

        />
    );
};

const ZipOrPostalInput = ({ label, name, ...props }) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e) => {
        let val = e.target.value;
        if (values.country === 'US') {
            val = formatZipCode(val);
        } else if (values.country === 'CA') {
            val = formatPostalCode(val);
        }
        setFieldValue(name, val);
    };

    return (
        <TextField
            {...field}
            {...props}
            name={name}
            label={label}
            value={field.value || ''}
            onChange={handleChange}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            fullWidth
            variant="standard"

        />
    );
};

const PhoneNumberInput = ({ label, name, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e) => {
        let val = e.target.value.replace(/[^\d]/g, '');
        if (val.length > 3 && val.length <= 6) {
            val = `(${val.slice(0, 3)}) ${val.slice(3)}`;
        } else if (val.length > 6) {
            val = `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;
        }
        setFieldValue(name, val);
    };

    return (
        <TextField
            {...field}
            {...props}
            name={name}
            label={label}
            value={field.value || ''}
            onChange={handleChange}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            fullWidth
            variant="standard"

        />
    );
};

const FormTextField = ({ name, label, ...props }) => {
    const [field, meta] = useField(name);
    return (
        <TextField
            {...field}
            {...props}
            label={label}
            value={field.value || ''}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            fullWidth
            variant="standard"

        />
    );
};

const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    company_name: Yup.string().required('Company name is required'),
    country: Yup.string().required('Country is required'),

    address_line_1: Yup.string().when('country', (country, schema) => {
        if (country === 'US' || country === 'CA') {
            return schema.required('Address is required');
        }
        return schema;
    }),

    city: Yup.string().when('country', (country, schema) => {
        if (country === 'US' || country === 'CA') {
            return schema.required('City is required');
        }
        return schema;
    }),

    state_abbreviation: Yup.string().when('country', (country, schema) => {
        if (country === 'US') {
            return schema
                .matches(/^[A-Z]{2}$/, 'Must be a valid 2-letter US State (e.g. NY)')
                .required('State is required for US');
        } else if (country === 'CA') {
            return schema
                .matches(/^[A-Z]{2}$/, 'Must be a valid 2-letter Province (e.g. AB)')
                .required('Province is required');
        }
        return schema;
    }),

    zipcode: Yup.string().when('country', (country, schema) => {
        if (country === 'US') {
            return schema
                .matches(/^\d{5}$/, 'Must be 5-digit US ZIP code (e.g. 12345)')
                .required('ZIP code is required for US');
        } else if (country === 'CA') {
            return schema
                .matches(/^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/, 'Must be a valid Canadian postal code (e.g. T2T 2T2)')
                .required('Postal code is required for Canada');
        }
        return schema;
    }),

    phone_number: Yup.string().required('Phone number is required'),

    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),

    confirmEmail: Yup.string()
        .oneOf([Yup.ref('email'), null], 'Emails must match')
        .required('Confirm your email')
});

const StepNavigation = ({ activeStep, handleBack, handleNext, isLastStep }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            sx={{
                borderColor: 'gray',
                color: 'gray',
                '&:hover': {
                    borderColor: 'gray',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
            }}
        >
            Back
        </Button>

        {isLastStep ? (
            <Button
                type="submit"
                variant="outlined"
                sx={{
                    borderColor: '#4EB6B5',
                    color: '#4EB6B5',
                    '&:hover': {
                        borderColor: '#4EB6B5',
                        backgroundColor: 'rgba(255, 0, 0, 0.04)'
                    }
                }}
            >
                Submit
            </Button>
        ) : (
            <Button
                onClick={handleNext}
                variant="outlined"
                sx={{
                    borderColor: '#4EB6B5',
                    color: '#4EB6B5',
                    '&:hover': {
                        borderColor: '#4EB6B5',
                        backgroundColor: 'rgba(255, 0, 0, 0.04)'
                    }
                }}
            >
                Next
            </Button>
        )}
    </Box>
);

const initialValues = {
    fullName: '',
    company_name: '',
    country: '',
    address_line_1: '',
    city: '',
    state_abbreviation: '',
    zipcode: '',
    phone_number: '',
    email: '',
    confirmEmail: ''
};

const ContractorForm = () => {
    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const api = useApi();

    const [activeStep, setActiveStep] = useState(0);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [passwordOpen, setPasswordOpen] = useState(true);

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);

    const handlePasswordSubmit = () => {
        setIsAuthorized(true);
    };
    const handleDialogClose = () => {
        setPasswordOpen(false);
    };

    const handleFinish = () => {
        setOpenSuccessDialog(false);
        navigate('/register');
    };

    const handleFormSubmit = async (values) => {
        const [first_name, last_name] = values.fullName.split(' ');
        const formData = {
            ...values,
            first_name,
            last_name,
            email: values.confirmEmail
        };
        delete formData.fullName;
        delete formData.confirmEmail;

        try {
            const response = await api.post('/con_registration', formData);
            if (response.status === 200) {
                setOpenSuccessDialog(true);
            } else {
                console.log('Contractor form submission failed');
            }
        } catch (error) {
            console.log('Contractor form submission failed', error);
        }
    };

    const renderStepContent = (step, values) => {
        switch (step) {
            case 0:
                return (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {t('personalInformation')}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormTextField name="fullName" label={t('fullName')} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name="company_name" label={t('companyName')} />
                            </Grid>
                        </Grid>
                    </Box>
                );

            case 1:
                return (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Geography:
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography>
                                    Which country do you reside in?
                                </Typography>
                                <FormTextField name="country" label="Country" select>
                                    <MenuItem value="US">United States of America</MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem>
                                </FormTextField>
                            </Grid>

                            {values.country && (
                                <>
                                    <Grid item xs={12}>
                                        <FormTextField
                                            name="address_line_1"
                                            label={t('address')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormTextField name="city" label={t('city')} />
                                    </Grid>

                                    {values.country === 'US' && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                <StateOrProvinceInput
                                                    name="state_abbreviation"
                                                    label={t('state')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <ZipOrPostalInput
                                                    name="zipcode"
                                                    label={t('zipCode')}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    {values.country === 'CA' && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                <StateOrProvinceInput
                                                    name="state_abbreviation"
                                                    label="Province"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <ZipOrPostalInput
                                                    name="zipcode"
                                                    label="Postal Code"
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Box>
                );

            case 2:
                return (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {t('contactInformation')}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PhoneNumberInput
                                    name="phone_number"
                                    label={t('phoneNumber')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name="email" label={t('email')} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name="confirmEmail" label={t('confirmEmail')} />
                            </Grid>
                        </Grid>
                    </Box>
                );

            default:
                return <div>Not Found</div>;
        }
    };

    return (
        <AppContainer>
            <AppHeader
                title={t('contractorOnboarding')}
                backButtonRoute="/"
            />

            <PasswordDialog
                open={passwordOpen}
                onClose={handleDialogClose}
                onPasswordSubmit={handlePasswordSubmit}
            />

            {isAuthorized && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                    mb: 5
                                }}
                            >
                                <FormIcon />
                                <Typography
                                    fontWeight="600"
                                    variant="subtitle1"
                                    textAlign="center"
                                >
                                    {t('letsGetStarted')}
                                </Typography>
                            </Box>

                            {renderStepContent(activeStep, values)}

                            <StepNavigation
                                activeStep={activeStep}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                isLastStep={activeStep === 2}
                            />

                            {/* Step indicator dots */}
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                {[0, 1, 2].map((step) => (
                                    <Box
                                        key={step}
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor:
                                                activeStep === step ? '#4EB6B5' : '#959292',
                                            mx: 1
                                        }}
                                    />
                                ))}
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}

            {/* Success Dialog */}
            <AppDialog
                open={openSuccessDialog}
                title={t('youAreAllSet')}
                handleConfirm={handleFinish}
                confirmText={t('finish')}
            >
                <SuccessAnimation />
                <Typography variant="subtitle2">
                    {t('trainerInfo')}
                </Typography>
            </AppDialog>
        </AppContainer>
    );
};

export default ContractorForm;
