import React from 'react';
import { Button, Typography } from "@mui/material";
import styled from '@emotion/styled';

const ButtonStyles = {
    margin: 2,
    width: '100%',
    borderRadius: 7,
    padding: '.5em',
};

const StyledButton = styled(Button)(({ theme, buttonvariant, variant }) => ({
    ...ButtonStyles,
    backgroundColor: buttonvariant === 'contained' ? (
        variant === 'primary'
            ? '#4EB6B5'
            : variant === 'secondary'
                ? '#37589D'
                : variant === 'tertiary'
                    ? '#D1D1D1'
                    : 'transparent'
    ) : 'transparent',
    color: buttonvariant === 'contained' ? 'white' : (
        variant === 'primary'
            ? '#4EB6B5'
            : variant === 'secondary'
                ? '#37589D'
                : variant === 'tertiary'
                    ? '#D1D1D1'
                    : 'inherit'
    ),
    border: buttonvariant === 'outlined' ? `1px solid ${
        variant === 'primary'
            ? '#4EB6B5'
            : variant === 'secondary'
                ? '#37589D'
                : variant === 'tertiary'
                    ? '#D1D1D1'
                    : 'inherit'
    }` : 'none',
    "&:hover": {
        backgroundColor: buttonvariant === 'contained' ? (
            variant === 'primary'
                ? '#4EB6B5'
                : variant === 'secondary'
                    ? '#37589D'
                    : variant === 'tertiary'
                        ? '#D1D1D1'
                        : 'transparent'
        ) : 'transparent',
        border: buttonvariant === 'outlined' ? `2px solid ${
            variant === 'primary'
                ? '#4EB6B5'
                : variant === 'secondary'
                    ? '#37589D'
                    : variant === 'tertiary'
                        ? '#D1D1D1'
                        : 'inherit'
        }` : 'none',
    },
    "&:disabled": {
        backgroundColor: buttonvariant === 'contained' ? '#D1D1D1' : 'transparent',
        color: buttonvariant === 'contained' ? '#A0A0A0' : '#D1D1D1',
        border: buttonvariant === 'outlined' ? '2px solid #D1D1D1' : 'none',
    },
}));

function AppButton({ buttonVariant = 'contained', variant = 'primary', type = "button", customStyles = {}, children, fontSize, fontWeight, ...props }) {
    return (
        <StyledButton type={type} style={customStyles} buttonvariant={buttonVariant} variant={variant} {...props}>
            <Typography fontWeight="bold">
                {children}
            </Typography>
        </StyledButton>
    );
}

export default AppButton;
