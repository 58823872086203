import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {Typography, InputAdornment, IconButton, Stack} from "@mui/material";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useApi } from "../../contexts/ApiProvider";
import AppDialog from "../shared/AppDialog";
import SuccessAnimation from "../../assets/Lotties/SuccessAnimation";
import {useNavigate} from "react-router-dom";
import AppTextInput from "../shared/AppTextInput";
import AppButton from "../shared/AppButton";
import {RegistrationContext} from "../../contexts/RegistrationContext";

const passScheme = (t) => Yup.object().shape({
    newPassword: Yup.string().matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, t('registerConPassword.passReq')).required(t('registerConPassword.newPassReq')),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], t('registerConPassword.passMatch')).required(t('registerConPassword.confirmPassReq'))
});

function RegisterConPassword() {
    const { t } = useTranslation('register');
    const api = useApi();
    const navigate = useNavigate();
    const { formState, prevStep } = useContext(RegistrationContext);

    const [showPassword, setShowPassword] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const handleShowPassword = () => setShowPassword(!showPassword);
    useEffect(() => {
        return () => {
            localStorage.removeItem('contractorToken');
        };
    }, []);

    const handlePasswordSubmit = async (values) => {
        const contractorToken = localStorage.getItem('contractorToken');
        const passData = {
            email: formState.email,
            new_password: values.newPassword,
            contractor_token: contractorToken,
            phone_number: formState.phoneNumber,
        };

        try {
            const response = await api.put('/con_registration', passData);
            if (response.status === 200) {
                setOpenSuccess(true)
                localStorage.removeItem('contractorToken');
            } else {
                console.error('Password creation failed');
            }
        } catch (error) {
            console.error('Password creation failed', error);
        }
    };

    const handleFinish = () => {
        setOpenSuccess(false);
        navigate('/')
    };

    return (
        <>
            <Formik
                initialValues={{ newPassword: '', confirmPassword: '' }}
                validationSchema={passScheme(t)}
                onSubmit={handlePasswordSubmit}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Stack alignItems="center">
                            <Typography variant="h6">{t('registerConPassword.justNeedPassword')}</Typography>
                            <Typography variant="subtitle1" color="secondaryText" textAlign="center">{t('registerConPassword.createPasswordInstructions')}</Typography>
                        </Stack>
                        <Stack spacing={1} my={2} >
                            <AppTextInput
                                type={showPassword ? "text" : "password"}
                                label={t('registerScreen.password')}
                                name="newPassword"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <AppTextInput
                                type={showPassword ? "text" : "password"}
                                label={t('registerScreen.confirmPassword')}
                                name="confirmPassword"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                        <Stack direction="column" spacing={1} my={2}>
                            <AppButton variant="primary" type="submit">{t('registerScreen.finish')}</AppButton>
                            <AppButton buttonVariant="outlined" variant="secondary" onClick={prevStep}>{t('registerScreen.back')}</AppButton>
                        </Stack>
                    </Form>
                )}
            </Formik>
            <AppDialog
                open={openSuccess}
                title={t('registerConPassword.passwordCreatedTitle')}
                handleConfirm={handleFinish}
                confirmText={t('registerScreen.finish')}
            >
                <SuccessAnimation />
                <Typography variant="subtitle2">
                    {t('registerConPassword.passwordCreatedContent')}
                </Typography>
            </AppDialog>
        </>

    );
}

export default RegisterConPassword;
